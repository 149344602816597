<template>
  <div id="CoursesDetail">
    <div class="header">
      <div class="top">
        <img :src="Global.changeImg(seriesDetail.thumbimgurl)"/>
      </div>
      <div class="list">
        <div>
          <span>{{seriesDetail.name}}</span>
          <span>¥{{product.price}}</span>
        </div>
        <div>
          <span>{{seriesDetail.created_at.split('T')[0]}}</span>
          <span>{{seriesDetail.pay_log_count}}人已购买课程</span>
        </div>
      </div>
    </div>

    <div class="personalInfo">
      <div >
        <p  class="second">课程内容</p>
        <p  class="third">{{seriesDetail.summary}}</p>
        <p  class="third" v-html="seriesDetail.content"></p>
      </div>
      <div style="padding-right:0" v-if="series_guest.length">
        <p  class="second">课程嘉宾</p>
        <div class="familiar">
          <div v-for="(i,item) in series_guest" :key="item" @click="toIntroduction(i.user_id)">
            <img :src="Global.changeImg(i.user.headimgurl)" />
            <p>{{i.member_info.name}}</p>
            <p>{{i.member_info.company}}</p>
            <p>{{i.member_info.job}}</p>
          </div>
          <!-- <div>
            <img src="../../assets/image/home/image1.png" />
            <p>杨轩</p>
            <p>曲速资本创始人</p>
            <p>曲速资本创始人</p>
          </div> -->
        </div>
      </div>
      <div style="margin-bottom:0;padding-bottom:0.5rem">
        <p  class="second">课程目录</p>
        <div class="course" v-for="(i,item) in course_info" :key="item">
          <p>{{i.title}}</p>
          <p><span> </span><span @click="lookCour(i.id)">观看课程</span></p>
        </div>
      </div>

    </div>

    <div class="fixedBottom">
      <span>限时抢购:<span>¥{{product.price}}</span></span>
      <span v-if="!seriesDetail.pay_log.length" @click="toBuy">立即订阅</span>
      <span v-else>已订阅</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CoursesDetail',
   data(){
    return{
      liveNow:1,
      seriesId:'',
      seriesDetail:'',
      series_guest:'',
      course_info:'',
      product:'',
      isPay:0,
      product_id:'',
      userStatus:'',//1，3,4不能看视频
    }
  },
  mounted(){
    this.seriesId = this.$route.query.seriesId
    this.axios({
      method: 'GET',
      url: '/series/getSeriesDetail?seriesId='+this.seriesId,
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      async:false
    }).then((res) => {
      if(res.data.code == 0){
        this.seriesDetail = res.data.data.detail;
        this.series_guest = this.seriesDetail.series_guest
        this.course_info = this.seriesDetail.course_info
        this.product = this.seriesDetail.product
        this.userStatus = res.data.data.userStatus
      }
    })
  },
  methods:{
    // 跳转
    toIntroduction(id){
      this.$router.push({
        path: '/Introduction?id='+id,
      })
    },
    toBuy(){
      this.product_id = this.product.id

      // 如果金额为 0，则请求订单接口，否则正常支付
      if (this.product.price == 0) {
        this.skipPay()
      } else {
        this.prepay()
      }
    },
    skipPay(){
      this.axios({
        method: 'POST',
        url: `/series/freeSubscribe/${this.seriesDetail.id}`,
        headers: {
          'Authorization': `Bearer ${this.Global.getCookie('token')}` 
        },
        data: {}
      }).then( (res) => {
        if(res.data.code == 0){
          //免费
          this.$toast('订阅成功！')
          this.seriesDetail.pay_log = [1]
        } else {
          this.$toast(res.data.message)
        }
      })
    },
    prepay(){
      if( !this.isPay ){
        this.isPay = 1
        this.axios({
          method: 'POST',
          url: '/payment/prepay',
          headers: {
            'Authorization': `Bearer ${this.Global.getCookie('token')}` 
          },
          data: {
            productId: this.product_id,
            transmit:''
          }
        }).then( (res) => {
          if(res.data.code == 0){
            if(res.data.data.redirect){
              //免费
              this.$toast('购买成功！')
              setTimeout(() => {
                this.$router.push('/SignUpEnd?type=c&activityId='+this.activityId)
              }, 1000)
            }else{
              this.callPay(res.data.data)
            }
          }else{
            this.isPay = 0
            this.$toast(res.data.message)
          }
        })
      }
    },
    // 微信调起支付
    callPay(res){
      if (typeof WeixinJSBridge == "undefined"){
        if( document.addEventListener ){
          document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady(res), false);
        }else if (document.attachEvent){
          document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady(res)); 
          document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady(res));
        }
      }else{
        this.onBridgeReady(res);
      }
    },
    onBridgeReady(data){
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest', data.config,
        (res) => {
          if(res.err_msg == "get_brand_wcpay_request:ok" ){
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            this.$toast('购买成功！')
            this.$router.push('/Home?showQrCode=1')
          }else if(res.err_msg == "get_brand_wcpay_request:cancel"){
            this.isPay = 0
          }else if(res.err_msg == "get_brand_wcpay_request:fail"){
            this.isPay = 0
            this.$toast('支付失败')
          }
      }); 
    },
    lookCour(id){
      // 如果用户不是会员
      if(this.userStatus == 1 || this.userStatus == 3 || this.userStatus == 4 ){
        this.$toast('请先购买会员')
        var _this = this;
        setTimeout(function(){
          _this.$router.push('/MemberProduct')
        },1000)
      }else{
        // 如果已经订阅
        if(this.seriesDetail.pay_log.length){
          this.$router.push('/CoursesDetailLink?courseId='+id)
        }else{
          this.$toast('请先订阅课程')
        }
      }
    },
    getWeek(a){
      switch(a){
        case 1:
        return '周一';
        case 2:
        return '周二';
        case 3:
        return '周三';
        case 4:
        return '周四';
        case 5:
        return '周五';
        case 6:
        return '周六';
        case 7:
        return '周日';
      }
    }
  }
}
</script>
<style>
.third img{
  width: 100%;
}
</style>
<style lang="less" scoped>
#CoursesDetail{
  .fixedBottom{
    position: fixed;
    width: 100%;
    height: 0.49rem;
    align-items: center;
    padding: 0 0.15rem;
    display: flex;
    bottom: 0;
    background: #23252E;
    border-top: 1px solid #32353F61;
    justify-content: space-between;
    box-sizing: border-box;
    max-width: 750px;
    >span:first-child{
      color: #989AB1;
      font-size: 0.13rem;
      margin-right: 0.06rem;
      display: flex;
      align-items: center;
      >span{
        color: #FF4A4A;
        font-size: 0.21rem;
      }
    }
    >span:last-child{
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      background: #3890FF;
      border-radius: 0.3rem;
      height: 0.3rem;
      width: 1.5rem;
    }
    
  }
  .course{
    margin: 0.15rem 0 0.11rem;
    >p:first-child{
      font-size: 0.13rem;
      padding-left: 0.26rem;
      color: #989AB1;
    }
    >p:last-child{
      display: flex;
      justify-content: space-between;
      margin-top: 0.03rem;
      align-items: center;
      >span:first-child{
        font-size: 0.12rem;
        color: #989AB1;
        padding-left: 0.26rem;
      }
      >span:last-child{
        font-size: 0.12rem;
        height: 0.25rem;
        border-radius: 0.25rem;
        color: #3890FF;
        border: 1px solid #3890FF;
        line-height: 0.27rem;
        padding: 0 0.13rem;
        transform: scale(0.8);
      }
    }
  }
  .list{
    >div:first-child{
      display: flex;
      align-items: center;
      justify-content: space-between;
      >span:first-child{
        color: #F6F7FD;
        font-size: 0.15rem;
      }
      >span:last-child{
        color: #FF4A4A;
        font-size: 0.15rem;
      }
    }
    >div:last-child{
      color: #727387;
      font-size: 0.12rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0.06rem 0 0.08rem;
    }
  }
  
  .familiar{
    overflow-x: scroll;
    padding-top: 0.15rem;
    margin-bottom: 0.1rem;
    white-space: nowrap;
    >div{
      width: 1.29rem;
      border-radius: 0.05rem;
      margin-right: 0.15rem;
      text-align: center;
      display: inline-block;
      background:#3D404F;
      >img{
        display: inline-block;
        width: 0.54rem;
        margin: 0.1rem 0 0.09rem;
        border-radius: 50%;
      }
      >p:nth-child(2){
        color: #FFFFFF;
        font-size: 0.14rem;
      }
      >p:nth-child(3){
        color: #9091A3;
        font-size: 0.12rem;
        margin: 0.03rem 0 0.02rem;
      }
      >p:nth-child(4){
        color: #9091A3;
        font-size: 0.12rem;
        margin: 0.03rem 0 0.1rem;
      }
    }
  }
  .personalInfo{
    >div{
      padding: 0.15rem 0.15rem 0.1rem;
      margin-bottom: 0.11rem;
      background: #23252E;
      .third{
        color: #989AB1;
        font-size: 0.13rem;
        line-height: 0.17rem;
        padding: 0.15rem 0 0.1rem;
      }
      .second{
        color: #F6F7FD;
        font-size: 0.15rem;
      }
    }
  }
  
  .header{
    background: #23252E;
    margin-bottom: 0.11rem;
    padding: 0 0.15rem 0.15rem;
    overflow: hidden;
    >.top{
      position: relative;
      >img{
        width: 100%;
        margin: 0.15rem 0;
      }
    }
  }
    
}
</style>